import { createStore } from "vuex";
import { set, get, ref as dbRef } from "firebase/database";
import { db } from "@/firebaseConfig"; // Import your Firebase database instance

const store = createStore({
  state() {
    return {
      isAuthenticated: false,
      user: null,
      role: "guest",
    };
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.isAuthenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_ROLE(state, value) {
      state.role = value;
    },
  },
  actions: {
    setAuthenticated({ commit }, value) {
      commit("SET_AUTHENTICATED", value);
    },
    setUser({ commit }, value) {
      commit("SET_USER", value);
    },
    async setRole({ commit, getters }) {
      const DEFAULT_ROLE = "guest";
      const user = getters.getUser;
      if (!user || !user.uid) {
        throw new Error("User is not authenticated or invalid.");
      }

      const userRef = dbRef(db, `users/${user.uid}`);

      try {
        const snapshot = await get(userRef);
        let data = snapshot.val();

        if (!data) {
          data = {
            role: DEFAULT_ROLE,
            email: user.email,
          };
          await set(userRef, data);
        }

        const { role } = data;
        commit("SET_ROLE", role);
      } catch (error) {
        console.error("Error accessing Realtime Database:", error);
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    getUser(state) {
      return state.user;
    },
    getRole(state) {
      return state.role;
    },
  },
});

export default store;
