<template>
  <div id="app" class="page-container">
    <div class="header" ref="imageContainer">
      <img class="logo" alt="Logo" src="./assets/logo.png" />
    </div>
    <NavBar />
    <div class="content">
      <router-view />
    </div>
    <div class="footer">
      <a href>
        <img src="./assets/js.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/css3-1.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/firebase.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/vs.svg" class="stack" />
      </a>
      <a>
        <img src="./assets/nodejs.svg" class="stack" />
      </a>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, provide } from "vue";
import * as firebaseui from "firebaseui";
import { auth } from "./firebaseConfig"; // Use the shared auth instance
import NavBar from "./components/NavBar.vue"; // Import NavBar component

export default {
  name: "App",
  components: { NavBar },
  setup() {
    const imageContainer = ref(null);

    // Create a promise to resolve when ui is initialized
    let uiResolve;
    const uiPromise = new Promise((resolve) => {
      uiResolve = resolve;
    });
    provide("firebaseUIPromise", uiPromise);

    // Create the ui instance as a computed ref
    const ui = computed(() => {
      const uiInstance = new firebaseui.auth.AuthUI(auth);
      uiResolve(uiInstance); // Resolve the promise when ui is created
      return uiInstance;
    });
    provide("firebaseUI", ui);

    onMounted(() => {
      // Trigger the evaluation of the computed property
      const uiInstance = ui.value; // Access ui.value to trigger evaluation
      console.log(uiInstance ? "" : "UI Not Ready"); // You should see the uiInstance logged
    });

    return {
      imageContainer,
    };
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $text-color;
}

.stack {
  margin: 40px 15px 15px 15px;
  width: 40px;
  height: 40px;
}

.stack:hover {
  margin-top: 35px;
  height: 45px;
}

.header {
  align-items: center;
}

.logo {
  max-width: 100%;
  min-width: 380px;
  margin: 0 auto;
}

.content {
  flex-grow: 1; /* Makes content take up remaining space */
}

.page-container {
  background-color: $background-color;
  padding: 40px;
  width: 80%;
  margin: auto;
  margin-top: 60px;
  border-radius: 8px;
  border: 3px solid green;
  display: flex;
  flex-direction: column;
  height: 750px;
}

@media (max-width: 800px) {
  .page-container {
    background-color: #aaf8d5dc;
    padding: 0px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    margin: 0px;
    border-radius: 0px;
    border: 0px solid green;
  }
}

html,
body {
  margin: 0px;
  height: 100%;
}

body {
  background-image: url("./assets/green-background2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>