<template>
  <div class="nav">
    <ul v-if="isAuthenticated">
      <li v-if="isUser">
        <router-link to="/">Home</router-link>
      </li>
      <li v-if="isUser">
        <router-link to="/code">Code</router-link>
      </li>
      <li v-if="isUser">
        <router-link to="/ereader">eReader</router-link>
      </li>
      <li class="logout-button">
        <a @click="logout">Log Out</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { signOut } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Use the shared auth instance
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  name: "NavBar",
};
</script>

<script setup>
const store = useStore();
const router = useRouter();
const isAuthenticated = computed(() => store.getters.isAuthenticated);
const isUser = computed(() => store.getters.getRole === "user");
const logout = async () => {
  try {
    await signOut(auth);
    // Optionally, redirect to the login page or perform other actions
    // after logout, like clearing the store:
    store.dispatch("setAuthenticated", false);
    store.dispatch("setUser", null);
    router.push({ name: "login" });
  } catch (error) {
    console.error("Error signing out:", error);
    // Handle the error, e.g., display an error message to the user
  }
};
</script>

<style scoped lang="scss">
.nav {
  text-align: center;
  padding: 10px;
}

.nav ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  display: flex; /* Make the list horizontal */
  gap: 15px; /* Add spacing between items */
  justify-content: center; /* Center the menu items horizontally */
}

.nav li {
  display: inline;
}

.nav a {
  text-decoration: none;
  color: $link-color;
  font-weight: bold;
}

.nav a:hover {
  color: $link-hover-color;
  cursor: pointer;
}
</style>
