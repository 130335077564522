import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import {
  connectFirestoreEmulator,
  getFirestore,
  //setLogLevel,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIG0kcnQtLiPe-GJp_t_Hd472aS4NKn4c",
  authDomain: "shamudeen.com",
  databaseURL: "https://shamudeen-a754e-default-rtdb.firebaseio.com",
  projectId: "shamudeen-a754e",
  storageBucket: "shamudeen-a754e.firebasestorage.app",
  messagingSenderId: "468787916272",
  appId: "1:468787916272:web:7554aec448e11848e90135",
  measurementId: "G-XN2QBDCSD4",
};

// Set logging level for Firebase
//setLogLevel("debug");

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

//initialize firebase auth
const auth = getAuth(app);

// Get Firestore instance and check if we are on localhost for emulator
const firestore = getFirestore();

if (location.hostname === "localhost") {
  connectFirestoreEmulator(firestore, "127.0.0.1", 8080); // Default port for Firestore emulator
  connectDatabaseEmulator(db, "localhost", 9000);
  connectAuthEmulator(auth, "http://127.0.0.1:9099"); // Add 'http://'
}

export { app, db, auth, firestore };
