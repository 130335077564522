import { createStore } from "vuex";
import {
  getDatabase,
  ref as dbRef,
  query,
  get,
  update,
  push,
  equalTo,
  orderByChild,
} from "firebase/database";

const store = createStore({
  state() {
    return {
      books: {}, // Store multiple books as an object
    };
  },
  mutations: {
    SET_BOOKS(state, books) {
      state.books = books;
    },
    ADD_BOOK(state, book) {
      state.books = { ...state.books, [book.id]: book };
    },
    UPDATE_BOOK(state, { id, book }) {
      state.books = { ...state.books, [id]: book };
    },
    DELETE_BOOK(state, id) {
      delete state.books[id];
    },
  },
  actions: {
    async fetchBooks({ commit }, uid) {
      const db = getDatabase();
      const booksRef = dbRef(db, "book");

      try {
        const booksQuery = query(
          booksRef,
          orderByChild("user_id"),
          equalTo(uid)
        );

        const snapshot = await get(booksQuery);
        const data = snapshot.val();
        if (data) {
          // Convert the object into an array, sort by timestamp, and then commit to the store
          const sortedBooks = Object.entries(data)
            .map(([key, book]) => ({ key, ...book })) // Map the entries to include the key as part of the book object
            .sort((a, b) => {
              const dateA = a.timestamp ? new Date(a.timestamp) : new Date(0); // Default to epoch if no timestamp
              const dateB = b.timestamp ? new Date(b.timestamp) : new Date(0); // Default to epoch if no timestamp
              return dateB - dateA; // Sort in descending order (newest first)
            })
            .reduce((acc, { key, ...book }) => {
              acc[key] = book; // Use the original keys while keeping the sorted books
              return acc;
            }, {});

          commit("SET_BOOKS", sortedBooks);
        } else {
          console.warn("No books found.");
        }
      } catch (error) {
        console.error("Error fetching books:", error);
      }
    },
    async addBook({ commit }, book) {
      const db = getDatabase();
      const booksRef = dbRef(db, "book");
      try {
        const newBookRef = await push(booksRef, book);
        commit("ADD_BOOK", { id: newBookRef.key, ...book });
      } catch (error) {
        console.error("Error adding book:", error);
      }
    },
    async updateBook({ commit }, { id, book }) {
      const db = getDatabase();
      const bookRef = dbRef(db, `book/${id}`);
      try {
        await update(bookRef, book);
        commit("UPDATE_BOOK", { id, book });
      } catch (error) {
        console.error("Error updating book:", error);
      }
    },
    async deleteBook({ commit }, id) {
      const db = getDatabase();
      const bookRef = dbRef(db, `book/${id}`);
      try {
        await update(bookRef, { user_id: "" });
        commit("DELETE_BOOK", id);
      } catch (error) {
        console.error("Error deleting book:", error);
      }
    },
  },
  getters: {
    allBooks(state) {
      return state.books;
    },
    getBook: (state) => (id) => {
      return state.books[id];
    },
  },
});

export default store;
