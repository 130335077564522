<script setup>
import { onMounted, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import firebase from "firebase/compat/app";

const router = useRouter();
const store = useStore();
const isAuthenticating = ref(true); // Add a loading state
const ui = inject("firebaseUI"); // Inject the ui instance
const uiPromise = inject("firebaseUIPromise");

onMounted(async () => {
  await uiPromise;

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: async (authResult) => {
        const user = authResult.user;
        await successfullyAuthenticated(user);
        return false;
      },
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],
  };
  ui.value.start("#firebaseui-auth-container", uiConfig);
  isAuthenticating.value = false; // Authentication check complete
});

async function successfullyAuthenticated(user) {
  await store.dispatch("setAuthenticated", true);
  await store.dispatch("setUser", user);
  router.push({ name: "home" }); // Redirect to home
}
</script>

<template>
  <div class="login-form-container">
    <div class="altbuttons">
      <div class="row">
        <section id="firebaseui-auth-container"></section>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.signInForm > .row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.login-form-container {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  border: none;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login {
  width: 120px;
  height: 40px;
}

/* .register {
  background-color: rgb(38, 68, 38);
  color: white;
  width: 188px;
  height: 28px;
  padding: 4px;
  border-radius: 4px;
} */

.right {
  text-align: right;
}
</style>
