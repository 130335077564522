
<template>
  <div v-if="isLoading">
    <p>Loading book details...</p>
  </div>
  <div v-else>
    <!-- Add Book Button -->
    <div class="add-book-button">
      <button @click="showAddBookPopup">Add Book</button>
    </div>

    <!-- Popup Dialog -->
    <div v-if="isPopupVisible" class="popup-overlay">
      <div class="popup-dialog">
        <h2>Add Book</h2>
        <div>
          <input v-model="name" placeholder="Enter Name" />
        </div>
        <div>
          <input v-model="url" placeholder="Enter URL" />
        </div>
        <div>
          <input v-model="selector" placeholder="Enter Content Selector" />
        </div>
        <div>
          <input v-model="backSelector" placeholder="Enter Back Selector" />
        </div>
        <div>
          <input v-model="forwardSelector" placeholder="Enter Forward Selector" />
        </div>
        <div>
          <button @click="addBook">Save</button>
          <button @click="closeAddBookPopup">Cancel</button>
        </div>
      </div>
    </div>

    <!-- Display Books Table -->
    <div class="display-books">
      <table border="1" cellspacing="0" cellpadding="8">
        <thead>
          <tr>
            <th>Name</th>
            <th v-if="editingRowKey !== null">URL</th>
            <th v-if="editingRowKey !== null">Content Selector</th>
            <th v-if="editingRowKey !== null">Forward Selector</th>
            <th v-if="editingRowKey !== null">Back Selector</th>
            <th v-if="editingRowKey === null">Last Read</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="([key, item]) in paginatedBooks" :key="key">
            <td>
              <div v-if="editingRowKey === key">
                <input v-model="books[key].name" />
              </div>
              <div v-else>{{ item.name }}</div>
            </td>
            <td v-if="editingRowKey !== null">
              <div v-if="editingRowKey === key">
                <input v-model="books[key].url" />
              </div>
              <div v-else>{{ item.url }}</div>
            </td>
            <td v-if="editingRowKey !== null">
              <div v-if="editingRowKey === key">
                <input v-model="books[key].selector" />
              </div>
              <div v-else>{{ item.selector }}</div>
            </td>
            <td v-if="editingRowKey !== null">
              <div v-if="editingRowKey === key">
                <input v-model="books[key].forward_selector" />
              </div>
              <div v-else>{{ item.forward_selector }}</div>
            </td>
            <td v-if="editingRowKey !== null">
              <div v-if="editingRowKey === key">
                <input v-model="books[key].back_selector" />
              </div>
              <div v-else>{{ item.back_selector }}</div>
            </td>
            <td v-if="editingRowKey === null">
              <div v-if="editingRowKey === key">
                <input v-model="books[key].timestamp" />
              </div>
              <div v-else>{{ item.timestamp }}</div>
            </td>
            <td>
              <div class="button-row">
                <button v-if="editingRowKey === key" class="icon-button" @click="saveBook(key)">
                  <img src="../assets/icons/save.png" class="img" alt="save" />
                </button>
                <button v-if="editingRowKey === key" class="icon-button" @click="closeEdit">
                  <img src="../assets/icons/close.png" class="img" alt="close" />
                </button>
                <button v-if="editingRowKey !== key" class="icon-button" @click="editBook(key)">
                  <img src="../assets/icons/edit.png" class="img" alt="edit" />
                </button>
                <button v-if="editingRowKey !== key" class="icon-button" @click="deleteBook(key)">
                  <img src="../assets/icons/trash.png" class="img" alt="trash" />
                </button>
                <button
                  v-if="editingRowKey !== key"
                  class="icon-button"
                  @click="navigateToBook(item, key)"
                >
                  <img src="../assets/icons/book.png" class="img" />
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="pagination-controls">
      <button v-if="currentPage !== 1" class="pagination-button" @click="prevPage">&lt;</button>
      <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <span>{{ currentPage }}</span>
      <button v-if="currentPage !== totalPages" class="pagination-button" @click="nextPage">&gt;</button>
      <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import bookStore from "@/stores/book-store";
import userStore from "@/stores/user-store";

const editingRowKey = ref(null); // Tracks the currently editing row key
const isPopupVisible = ref(false); // Tracks if the popup is visible
const url = ref("");
const name = ref("");
const selector = ref(".post__body");
const backSelector = ref(".post__nav-link.next");
const forwardSelector = ref(".post__nav-link.prev");
const router = useRouter();
const isLoading = ref(true);
const uid = computed(() => userStore.getters.getUser.uid);
const books = computed(() => bookStore.getters.allBooks);

// Pagination states
const currentPage = ref(1);
const rowsPerPage = ref(5);

const paginatedBooks = computed(() => {
  const startIndex = (currentPage.value - 1) * rowsPerPage.value;
  const endIndex = startIndex + rowsPerPage.value;
  return Object.entries(books.value).slice(startIndex, endIndex);
});

const totalPages = computed(() => {
  return Math.ceil(Object.keys(books.value).length / rowsPerPage.value);
});

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
};

const prevPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
};

const fetchBookData = async () => {
  try {
    // Fetch all books if not already fetched
    if (!Object.keys(bookStore.getters.allBooks).length) {
      await bookStore.dispatch("fetchBooks", uid.value);
    }
  } catch (error) {
    console.error("Error fetching books:", error);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetchBookData();
});

const navigateToBook = (item, key) => {
  router.push({
    name: "Book",
    query: {
      id: key,
    },
  });
};

const editBook = (key) => {
  editingRowKey.value = key; // Set the current editing row
};

const saveBook = (key) => {
  const updatedBook = books.value[key];
  bookStore.dispatch("updateBook", { id: key, book: updatedBook });
  closeEdit();
};

const closeEdit = () => {
  editingRowKey.value = null; // Exit edit mode
};

const deleteBook = (key) => {
  const isConfirmed = window.confirm(
    "Are you sure you want to delete this book?"
  );
  if (isConfirmed) {
    bookStore.dispatch("deleteBook", key);
  }
};

const showAddBookPopup = () => {
  isPopupVisible.value = true;
};

const closeAddBookPopup = () => {
  isPopupVisible.value = false;
};

const addBook = () => {
  if (!name.value) {
    alert("Please fill in a name.");
    return;
  }

  const newBook = {
    name: name.value,
    url: url.value,
    selector: selector.value,
    back_selector: backSelector.value,
    forward_selector: forwardSelector.value,
    user_id: uid.value,
  };

  bookStore.dispatch("addBook", newBook).then(() => {
    closeAddBookPopup();
  });
};
</script>
<style scoped lang="scss">
h2 {
  color: $secondary-text-color;
}

.pagination-controls {
  padding: 10px;
  font-weight: bold;
}

.button-row {
  display: flex; /* Use flexbox to arrange children in a row */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 10px; /* Add spacing between buttons */
  padding: 8px; /* Optional: Add padding inside the cell */
}

.pagination-button {
  font-size: 18px;
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
  filter: brightness(0) saturate(100%); /* Default black color */
}

.pagination-button:hover {
  transform: scale(1.5); /* Enlarge the image by 50% */
  filter: brightness(0) saturate(100%) invert(21%) sepia(76%) saturate(5000%)
    hue-rotate(260deg) brightness(80%) contrast(115%);
}

.icon-button {
  background: none;
  border: none;
  padding: 0;
  margin: 2px;
  cursor: pointer;
}

.img {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
  filter: brightness(0) saturate(100%); /* Default black color */
}

.img:hover {
  transform: scale(1.5); /* Enlarge the image by 50% */
  filter: brightness(0) saturate(100%) invert(21%) sepia(76%) saturate(5000%)
    hue-rotate(260deg) brightness(80%) contrast(115%);
}

.add-book-button {
  padding: 10px;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-dialog {
  background: $dark-green;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}

.popup-dialog h2 {
  margin-top: 0;
  font-size: 1.5em;
}

.popup-dialog div {
  margin-bottom: 10px;
}

.popup-dialog button {
  margin-right: 10px;
}

.display-books {
  display: flex;
  justify-content: center;
  align-items: center;
}

input {
  width: 80%;
}

table {
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  min-width: 425px;
}

td {
  background-color: rgba(255, 255, 255, 0.2);
  color: $text-color;
}

th {
  text-align: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

thead {
  th {
    background-color: $dark-green;
  }
}

tbody {
  tr {
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  td {
    position: relative;
    &:hover {
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: -9999px;
        bottom: -9999px;
        background-color: rgba(255, 255, 255, 0.2);
        z-index: -1;
      }
    }
  }
}
</style>
