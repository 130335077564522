<template>
  <div>
    <FileLoader :filePath="path" />
  </div>
  <div class="file_path">
    <input v-model="path" placeholder="File Path" />
  </div>
</template>

<script>
import FileLoader from "../components/FileLoader.vue";
import { ref } from "vue";

export default {
  components: {
    FileLoader,
  },
};
</script>
<script setup>
const path = ref("../ui/UiDropdown.vue");
</script>
<style scoped lang="scss">
.file_path {
  margin-top: 90px;
}
</style>
